$contentMaxWidth: 1280px;

.media-container {
  text-align: center;
  height: 100%;
  width: 95vw;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    width: 95vw;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: 75vw;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: 65vw;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    width: 55vw;
  }

  padding-bottom: 3em;
}

.video-container {
  position: relative;
  padding-bottom: 56.26%;
  height: 0;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &.offline {
    display: none;
  }
}

@mixin content-separator {
  height: 2px;
  width: 40px;
  background: linear-gradient(
                  to right,
                  $personal 33%,
                  $consulting 33%,
                  $consulting 66%,
                  $software 66%
  );
}

@mixin hexagon {
  /* Magic maths */
  clip-path: polygon(
                  50% 3%,
                  90% 28.3%,
                  90% 71.7%,
                  50% 97%,
                  10% 71.7%,
                  10% 28.3%
  );
}

@mixin parallax-background-image($image-url, $min-height, $opacity) {
  background-image: url($image-url);
  min-height: $min-height;
  position: relative;
  opacity: $opacity;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  pointer-events: none;
}
