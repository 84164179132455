$personal: #cf1f26;
$consulting: #00aced;
$software: #93bd44;
$red: $personal;
$blue: $consulting;
$green: $software;
$purple: #663399;
$orange: #ff5722;
$dark: #111111;
$grey: #767171;
$light: white;
$headerSeparator: #a09e9e;
$textColor: #a7a7a7;
$footerBackgroundColor: #0a0b0f;
$liveDotColor: #ff0000;
$mailLinkColor: #525252;
$footerNavLinkColor: #c5c5c5;
$youTubeColor: #ff0000;
$twitchColor: #6441a5;