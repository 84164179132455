@import '../../styles/index';

.loading {
  background-color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;

  .loading-content {
    display: flex;
    flex-direction: column;
    max-width: $contentMaxWidth;

    padding-top: 6em;
    padding-left: 1em;
    padding-right: 1em;
  }
}
