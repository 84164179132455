@import "../../styles/index";

.loadingSpinner__container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  padding: 5em;

  span {
    position: absolute;
    width: 2px;
    height: 0%;

    &.loadingSpinner__left-top {
      left: 0;
      animation: loadingSpinnerAnimation__left-top 5s ease infinite;
      background: $software;
    }

    &.loadingSpinner__right-top {
      right: 0;
      animation: loadingSpinnerAnimation__right-top 5s ease .4s infinite;
      background: $consulting;
    }

    &.loadingSpinner__right {
      right: 0;
      animation: loadingSpinnerAnimation__right 5s ease .8s infinite;
      background: $personal;
    }

    &.loadingSpinner__right-bottom {
      right: 0;
      animation: loadingSpinnerAnimation__right-bottom 5s ease 1.2s infinite;
      background: $software;
    }

    &.loadingSpinner__left-bottom {
      left: 0;
      animation: loadingSpinnerAnimation__left-bottom 5s ease 1.6s infinite;
      background: $consulting;
    }

    &.loadingSpinner__left {
      left: 0;
      animation: loadingSpinnerAnimation__left 5s ease 2s infinite;
      background: $personal;
    }
  }

  .loadingSpinner__group {
    position: absolute;
    width: 50px;
    height: 28px;
  }

  .loadingSpinner__group:nth-of-type(2) {
    transform: rotate(60deg)
  }

  .loadingSpinner__group:nth-of-type(3) {
    transform: rotate(-60deg)
  }
}

@keyframes loadingSpinnerAnimation__left-top {
  0% {
    bottom: 0;
    height: 0
  }
  6.944444444% {
    bottom: 0;
    height: 100%
  }
  50% {
    top: 0;
    height: 100%
  }
  59.944444433% {
    top: 0;
    height: 0
  }
}

@keyframes loadingSpinnerAnimation__right-top {
  0% {
    top: 0;
    height: 0
  }
  6.944444444% {
    top: 0;
    height: 100%
  }
  50% {
    bottom: 0;
    height: 100%
  }
  59.944444433% {
    bottom: 0;
    height: 0
  }
}

@keyframes loadingSpinnerAnimation__right {
  0% {
    top: 0;
    height: 0
  }
  6.944444444% {
    top: 0;
    height: 100%
  }
  50% {
    bottom: 0;
    height: 100%
  }
  59.94444443% {
    bottom: 0;
    height: 0
  }
}

@keyframes loadingSpinnerAnimation__right-bottom {
  0% {
    top: 0;
    height: 0
  }
  6.944444444% {
    top: 0;
    height: 100%
  }
  50% {
    bottom: 0;
    height: 100%
  }
  59.94444443% {
    bottom: 0;
    height: 0
  }
}

@keyframes loadingSpinnerAnimation__left-bottom {
  0% {
    bottom: 0;
    height: 0
  }
  6.944444444% {
    bottom: 0;
    height: 100%
  }
  50% {
    top: 0;
    height: 100%
  }
  59.94444443% {
    top: 0;
    height: 0
  }
}

@keyframes loadingSpinnerAnimation__left {
  0% {
    bottom: 0;
    height: 0
  }
  6.944444444% {
    bottom: 0;
    height: 100%
  }
  50% {
    top: 0;
    height: 100%
  }
  59.94444443% {
    top: 0;
    height: 0
  }
}