@import "styles/index";
@import-normalize;

*,
*::after,
*::before {
  box-sizing: border-box;
}

html {
  color: $dark;
  background-color: $footerBackgroundColor;
  height: 100vh;
  font-size: 16px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  display: flex;
  flex-direction: column;

  p {
    word-break: break-word;
  }
}

#root {
  position: absolute;
  left: 0;
  top: 0;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  overflow: auto;
  overflow-x: hidden;
  // overflow-y: hidden;
}

.react-cookie-law-dialog.cookie-banner {
  top: auto !important;
  bottom: 0;

  .react-cookie-law-container {
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      border: 1px solid black;
      margin-left: 1em !important;

      &:hover {
        background-color: white !important;
        color: black !important;
      }
    }
  }
}
